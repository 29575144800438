import moment from "moment";

const validateForm = (form, isAccountForm) => {
  const errors = {
    email: null,
    emailRepeat: null,
    fullName: null,
    dob: null,
  };

  if (!form.email && !isAccountForm) {
    errors.email = "Please provide a valid email";
  }

  if (!form.emailRepeat && !isAccountForm) {
    errors.emailRepeat = "Please repeat your email";
  }

  if ((form.emailRepeat !== form.email) && !isAccountForm) {
    errors.emailRepeat = "Email addresses do not match";
  }

  if (!form.fullName) {
    errors.fullName = "Please provide your name";
  }

  const dob = moment(`${form.dobYear}-01-01`, "YYYY-MM-DD");

  if (!dob.isValid()) {
    errors.dob = "Please provide valid birthdate";
  }

  return errors;
};

export default validateForm;
